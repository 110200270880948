export default {
  namespaced: true,
  state: {
    url:
      localStorage.getItem('url') ||
      'https://api.pousadatucanodomarahu.com.br/',
  },
  mutations: {
    changeUrl(state, url) {
      state.url = url;
    },
  },
  actions: {
    setUrl({ commit }, { url }) {
      commit('changeUrl', url);
      localStorage.setItem('url', url);
    },
    // async updateUrl({ commit }) {
    //   return await fetch('https://apival.pousadatucanodomarahu.com.br/api/url', { method: 'GET' }).then((response) =>
    //     response.json().then((json) => {
    //       const url = JSON.parse(json[0].set);
    //       console.log(url.url);
    //       commit('changeUrl', url.url + '/');
    //     })
    //   );
    // },
  },
  getters: {
    getUrl(state) {
      return state.url;
    },
  },
};

// Local: http://127.0.0.1:8000/
// Validação: https://apival.pousadatucanodomarahu.com.br/
// Homologação: https://apihomolog.pousadatucanodomarahu.com.br/
// Produção: https://api.pousadatucanodomarahu.com.br/
