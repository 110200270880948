export default {
  namespaced: true,
  state: {
    scrollY: window.scrollY,
    viewportX: window.innerWidth,
    viewportY: window.innerHeight,
  },
  mutations: {
    setScrollY(state, y) {
      state.scrollY = y;
    },
    setViewportX(state, x) {
      state.viewportX = x;
    },
    setViewportY(state, y) {
      state.viewportY = y;
    },
  },
  actions: {
    setScrollY({ commit }, y) {
      commit('setScrollY', y);
    },
    setViewportX({ commit }, x) {
      commit('setViewportX', x);
    },
    setViewportY({ commit }, y) {
      commit('setViewportY', y);
    },
  },
  getters: {
    scrollY: (state) => state.scrollY,
    viewportX: (state) => state.viewportX,
    viewportY: (state) => state.viewportY,
  },
};
