export default {
  namespaced: true,
  state: {
    accommodation: {},
    accommodations: [],
  },
  mutations: {
    setAccommodation(state, accommodation) {
      state.accommodation = accommodation;
    },
  },
  actions: {
    async previewAccommodation(
      _,
      { start_date, end_date, diaria, value, adults, children, pet }
    ) {
      return await fetch('', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          start_date,
          end_date,
          diaria,
          value,
          adults,
          children,
          pet,
        }),
      })
        .then((response) => {
          switch (true) {
            case response.status >= 100 && response.status < 200:
              return false;
            case response.status >= 200 && response.status < 300:
              return true;
            case response.status >= 400 && response.status < 500:
              return false;
            case response.status >= 500 && response.status < 600:
              return false;
            default:
              return false;
          }
        })
        .catch((error) =>
          alert(`Erro ao tentar se comunicar com o servidor.\n\n${error}`)
        );
    },
    async fetchAccommodation({ rootGetters }, { id }) {
      return await fetch(
        rootGetters['url/getUrl'] + `api/accommodation/info/${id}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
        .then((response) => {
          switch (true) {
            case response.status >= 100 && response.status < 200:
              return false;
            case response.status >= 200 && response.status < 300:
              return {
                accommodation: response.json().then((json) => json),
                return: true,
              };
            case response.status >= 400 && response.status < 500:
              return false;
            case response.status >= 500 && response.status < 600:
              return false;
            default:
              return false;
          }
        })
        .catch((error) =>
          alert(`Erro ao tentar se comunicar com o servidor.\n\n${error}`)
        );
    },
  },
  getters: {
    async getAccommodations(state, getters, rootState, rootGetters) {
      return await fetch(rootGetters['url/getUrl'] + 'api/accommodations', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => {
          switch (true) {
            case response.status >= 100 && response.status < 200:
              return false;
            case response.status >= 200 && response.status < 300:
              return {
                accommodations: response.json().then((json) => json),
                return: true,
              };
            case response.status >= 400 && response.status < 500:
              return false;
            case response.status >= 500 && response.status < 600:
              return false;
            default:
              return false;
          }
        })
        .catch((error) =>
          alert(`Erro ao tentar se comunicar com o servidor.\n\n${error}`)
        );
    },
  },
};
