import { createRouter, createWebHashHistory } from 'vue-router';
import HomePage from '@/pages/home/HomePage.vue';
import store from '@/scripts/store/store';

export default createRouter({
  mode: 'history',
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'homePage',
      component: HomePage,
    },
    {
      path: '/accommodation/:id',
      name: 'accommodation',
      component: () => import('@/pages/home/AccommodationPage.vue'),
    },
    {
      path: '/accommodations',
      name: 'accommodations',
      component: () => import('@/pages/home/AccommodationsPage.vue'),
    },
    {
      path: '/signIn/:hash?',
      name: 'signIn',
      component: () => import('../../pages/home/SignIn.vue'),
      beforeEnter: (to, from, next) => {
        localStorage.getItem('token') ? next('/') : next();
      },
    },
    {
      path: '/signUp',
      name: 'signUp',
      protected: true,
      component: () => import('../../pages/home/SignUp.vue'),
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('../../pages/admin/DashBoard.vue'),
      beforeEnter: (to, from, next) => {
        (async () => ((await store.getters['user/getAuthentication']) ? next() : next('/signIn/1')))();
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: () => import('../../pages/Error/NotFoundPage.vue'),
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    return (
      savedPosition ||
      new Promise((resolve) => {
        setTimeout(() => {
          resolve({ behavior: 'smooth', top: 0 });
        }, 300);
      })
    );
  },
});
