<template>
  <div>
    <header
      v-if="watcher.viewportX >= 1024"
      :class="watcher.scrollY > watcher.viewportY ? 'bg-gradient-to-br from-white to-slate-100 py-0' : 'py-7'"
      class="fixed z-40 flex w-full place-content-between place-items-center px-7 transition-all duration-500 ease-in-out"
    >
      <div
        :class="
          watcher.scrollY > watcher.viewportY
            ? 'rounded-none bg-none shadow-none'
            : 'rounded-md bg-gradient-to-br from-white to-slate-100 shadow-md'
        "
        class="flex h-14 place-content-start place-items-center space-x-6 p-2 pl-6 hover:cursor-pointer"
        @click="goHome()"
      >
        <img src="@/assets/images/tucano.png" height="407" width="407" class="w-10" />
        <h1 class="Dreamwood pr-6 text-2xl underline">Tucano do Marahú</h1>
      </div>

      <div
        :class="
          watcher.scrollY > watcher.viewportY
            ? 'rounded-none bg-none shadow-none'
            : 'rounded-md bg-gradient-to-br from-white to-slate-100 shadow-md'
        "
        class="flex h-14 place-content-start place-items-center space-x-4 bg-gradient-to-br from-white to-slate-100"
      >
        <div>
          <nav>
            <ul class="flex space-x-4 pr-0 pl-4 font-medium">
              <li class="hover:cursor-pointer hover:text-green-600">
                <a href="/#">Home</a>
              </li>
              <li class="hover:cursor-pointer hover:text-green-600">
                <a @click="scrollSobre()">Sobre</a>
              </li>
              <li class="hover:cursor-pointer hover:text-green-600">
                <a @click="scrollFotos()">Fotos </a>
              </li>
              <router-link to="/accommodations" class="hover:cursor-pointer hover:text-green-600">
                Acomodações
              </router-link>
              <li class="hover:cursor-pointer hover:text-green-600">
                <a @click="scrollContato()">Contato</a>
              </li>
            </ul>
          </nav>
        </div>
        <onClickOutside
          :class="watcher.scrollY > watcher.viewportY ? 'rounded-none' : 'rounded-r-md'"
          class="group flex h-full w-14 place-content-center place-items-center bg-slate-300 hover:cursor-pointer hover:brightness-105"
          @click="openDropdown()"
          @trigger="[dropDown01, dropDown02] = [false, false]"
        >
          <i class="fa-solid fa-user group-hover:text-green-600"></i>
        </onClickOutside>
      </div>
      <!-- Dropdown01 ▼ -->
      <div
        v-show="dropDown01"
        class="fixed top-24 right-7 flex min-h-0 min-w-0 flex-col place-content-start place-items-center space-y-4 divide-y-2 rounded-md bg-gradient-to-br from-white to-slate-100 p-4 shadow-md transition-all duration-500 ease-in-out"
      >
        <button
          class="w-full rounded-md bg-gradient-to-br from-sky-400 to-blue-500 py-2 px-4 font-medium text-slate-100 shadow-md hover:brightness-105"
          @click="goSignIn()"
        >
          Fazer Login
        </button>
        <div class="pt-2 text-center text-xs">
          Ainda não possui cadastro?<br />
          <router-link to="/signUp" class="text-green-900 underline hover:text-green-800"
            >Cadastre-se Agora</router-link
          >
        </div>
      </div>
      <!-- Dropdown01 ▲ -->
      <!-- Dropdown02 ▼ -->
      <div
        v-show="dropDown02"
        class="fixed top-24 right-7 flex min-h-0 min-w-0 flex-col place-content-start place-items-center space-y-4 divide-y-2 rounded-md bg-gradient-to-br from-white to-slate-100 p-4 shadow-md transition-all duration-500 ease-in-out"
      >
        <button
          class="w-full rounded-md bg-gradient-to-br from-sky-400 to-blue-500 py-2 px-4 font-medium text-slate-100 shadow-md hover:brightness-105"
          @click="goAdmin()"
        >
          Acessar Painel
        </button>
        <div class="pt-2 text-center text-xs">
          <button class="text-green-900 underline hover:text-green-800" @click="signOut()">Sair</button>
        </div>
      </div>
      <!-- Dropdown02 ▲ -->
    </header>
    <header
      v-if="watcher.viewportX < 1024"
      class="fixed z-40 flex min-w-full place-content-between place-items-center bg-gradient-to-br from-white to-slate-100 p-3 shadow-md"
    >
      <a href="/#">
        <div class="flex flex min-w-0 min-w-0 place-content-start place-items-center space-x-2">
          <img src="@/assets/images/tucano.png" height="407" width="407" class="w-8" />
          <h1 class="Dreamwood pr-2 text-xl underline">Tucano do Marahú</h1>
        </div></a
      >
      <div class="flex space-x-4">
        <OnClickOutside
          class="rounded-full bg-slate-300 px-3 py-2 text-xl hover:cursor-pointer hover:text-green-600 hover:brightness-105"
          @trigger="[dropDown01, dropDown02] = [false, false]"
          @click="openDropdown()"
        >
          <i :class="(dropDown01 || dropDown02) && 'text-green-600'" class="fa-solid fa-user"></i>
        </OnClickOutside>

        <OnClickOutside
          class="rounded-full bg-slate-300 px-3 py-2 text-xl hover:cursor-pointer hover:text-green-600 hover:brightness-105"
          @trigger="menuIsOpen = false"
          @click="menuOpen()"
        >
          <i :class="menuIsOpen && 'text-green-600'" class="fa-solid fa-bars"></i>
        </OnClickOutside>
      </div>
      <!-- Menu Modal ▼ -->
      <div
        v-show="menuIsOpen"
        class="fixed top-24 right-7 flex min-h-0 min-w-0 flex-col place-content-start place-items-center space-y-4 divide-y-2 rounded-md bg-gradient-to-br from-white to-slate-100 p-4 shadow-md transition-all duration-500 ease-in-out"
      >
        <div>
          <nav>
            <ul class="flex flex-col space-y-4 text-center font-medium">
              <li class="hover:cursor-pointer hover:text-green-600">
                <a href="/#">Home</a>
              </li>
              <li class="hover:cursor-pointer hover:text-green-600">
                <a @click="scrollSobre()">Sobre</a>
              </li>
              <li class="hover:cursor-pointer hover:text-green-600">
                <a @click="scrollFotos()">Fotos </a>
              </li>
              <router-link to="/accommodations" class="hover:cursor-pointer hover:text-green-600">
                Acomodações
              </router-link>
              <li class="hover:cursor-pointer hover:text-green-600">
                <a @click="scrollContato()">Contato</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- Menu Modal ▲ -->
      <!-- Dropdown01 ▼ -->
      <div
        v-show="dropDown01"
        class="fixed top-24 right-7 flex min-h-0 min-w-0 flex-col place-content-start place-items-center space-y-4 divide-y-2 rounded-md bg-gradient-to-br from-white to-slate-100 p-4 shadow-md transition-all duration-500 ease-in-out"
      >
        <button
          class="w-full rounded-md bg-gradient-to-br from-sky-400 to-blue-500 py-2 px-4 font-medium text-slate-100 shadow-md hover:brightness-105"
          @click="goSignIn()"
        >
          Fazer Login
        </button>
        <div class="pt-2 text-center text-xs">
          Ainda não possui cadastro?<br />
          <router-link to="/signUp" class="text-green-900 underline hover:text-green-800"
            >Cadastre-se Agora</router-link
          >
        </div>
      </div>
      <!-- Dropdown01 ▲ -->
      <!-- Dropdown02 ▼ -->
      <div
        v-show="dropDown02"
        class="fixed top-24 right-7 flex min-h-0 min-w-0 flex-col place-content-start place-items-center space-y-4 divide-y-2 rounded-md bg-gradient-to-br from-white to-slate-100 p-4 shadow-md transition-all duration-500 ease-in-out"
      >
        <button
          class="w-full rounded-md bg-gradient-to-br from-sky-400 to-blue-500 py-2 px-4 font-medium text-slate-100 shadow-md hover:brightness-105"
          @click="goAdmin()"
        >
          Acessar Painel
        </button>
        <div class="pt-2 text-center text-xs">
          <button class="text-green-900 underline hover:text-green-800" @click="signOut()">Sair</button>
        </div>
      </div>
      <!-- Dropdown02 ▲ -->
    </header>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { OnClickOutside } from '@vueuse/components';
export default {
  name: 'HomeHeader',
  components: {
    OnClickOutside,
  },
  data() {
    return {
      menuIsOpen: false,
      dropDown01: false,
      dropDown02: false,
    };
  },
  computed: {
    ...mapState({
      watcher: (state) => state.watcher,
      router: (state) => state.router,
    }),
    ...mapGetters({
      getAuthentication: 'user/getAuthentication',
    }),
  },
  mounted() {
    addEventListener('scroll', () => {
      this.setScrollY(window.scrollY);
    });
    addEventListener('resize', () => {
      this.setViewportX(window.innerWidth);
    });
    addEventListener('resize', () => {
      this.setViewportY(window.innerHeight);
    });
  },
  methods: {
    ...mapActions({
      setScrollY: 'watcher/setScrollY',
      setViewportX: 'watcher/setViewportX',
      setViewportY: 'watcher/setViewportY',
      signOut: 'user/signOut',
    }),
    menuOpen() {
      this.menuIsOpen = !this.menuIsOpen;
      this.dropDown01 = false;
      this.dropDown02 = false;
    },
    goSignIn() {
      this.$router.push('/signIn');
    },
    goHome() {
      this.$router.push('/');
    },
    goAdmin() {
      this.$router.push('/admin');
    },
    openDropdown() {
      this.menuIsOpen = false;
      localStorage.getItem('token')
        ? [(this.dropDown02 = !this.dropDown02), (this.dropDown01 = false)]
        : [(this.dropDown01 = !this.dropDown01), (this.dropDown02 = false)];
    },
    scrollFotos() {
      this.$router.push('/');
      setTimeout(() => {
        window.scrollTo({
          top: document.querySelector('#fotos').offsetTop,
          behavior: 'smooth',
        });
      }, 500);
    },
    scrollSobre() {
      this.$router.push('/');
      setTimeout(() => {
        window.scrollTo({
          top: document.querySelector('#sobre').offsetTop,
          behavior: 'smooth',
        });
      }, 500);
    },
    scrollContato() {
      window.scrollTo({
        top: document.querySelector('#rodape').offsetTop,
        behavior: 'smooth',
      });
    },
  },
};
</script>
