<template>
  <div class="grid grid-cols-1">
    <HomeHeader />
    <!-- Sessão Hero ▼ -->
    <section
      class="HomeHero grid min-h-screen w-full place-content-center place-items-center bg-cover bg-fixed bg-center bg-no-repeat"
    >
      <!-- Boas vindas -->
      <h1 class="space-y-8 text-center">
        <p class="text-4xl font-medium italic lg:text-5xl">Boas-vindas à Pousada</p>
        <p class="Dreamwood text-5xl underline lg:text-8xl">Tucano do Marahú</p>
      </h1>
      <div class="arrow bounce-in-top absolute bottom-[3%] animate-bounce cursor-pointer" @click="scrollSobre()">
        <i class="fas fa-angle-down fa-3x"></i>
      </div>
    </section>
    <!-- Sessão Hero ▲ -->
    <!-- Sessão Sobre ▼ -->
    <section id="sobre" class="max-h-860-px relative flex h-screen items-center px-3 text-center lg:text-left">
      <div class="container mx-auto flex flex-wrap items-center">
        <div class="w-full px-4 md:w-8/12 lg:w-6/12 xl:w-6/12">
          <div class="pt-32 sm:pt-0">
            <h2 class="Dreamwood text-3xl font-medium lg:text-4xl">
              Natureza na Foz do Rio Amazonas
              <p class="mt-4 text-xl lg:text-2xl">Nasce com um novo conceito de contato com a natureza.</p>
            </h2>
            <hr class="my-4 border-t-2 border-amber-500" />
            <div class="space-y-4">
              <p>
                Nosso espaço é um misto de rusticidade e conforto, proporcionando a sensação de estar acolhido em sua
                própria casa.
              </p>
              <p>
                Localizada à Foz do Rio Amazonas, que impressiona pelo seu volume de água, possui atrativos e ares do
                campo, com horta orgânica, alimentação saudável, chalés familiares, redário e um diferencial: primamos
                pela paz, descanso e conforto dos nossos hóspedes.
              </p>
              <p>
                A pousada busca a sustentabilidade ambiental com uso de painéis solares, preservação da fauna e flora
                com árvores frutíferas como coco, bananas, sapotilha, acerola e açaí, onde extrai-se seu suco natural.
              </p>
            </div>
          </div>
        </div>
      </div>

      <img
        class="b-auto max-h-860-px absolute top-0 right-0 -mt-48 w-10/12 pt-16 sm:mt-0 sm:w-6/12"
        :class="watcher.viewportX >= 1024 ? 'visible' : 'hidden'"
        src="@/assets/images/pattern_vue.png"
        alt="..."
      />
    </section>
    <!-- Sessão Sobre ▲ -->
    <!-- Mais Informações ▼ -->
    <section class="relative mt-40 -mb-32">
      <div class="container mx-auto">
        <div class="flex flex-wrap items-center">
          <div class="md mr-auto ml-auto w-full px-4 lg:w-1/3">
            <div class="relative mb-6 flex w-full flex-col">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15956.471671090814!2d-48.399836!3d-1.073597!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbdb9df2ae509808a!2sPousada%20Tucano%20Do%20Marah%C3%BA!5e0!3m2!1spt-BR!2sbr!4v1654160518944!5m2!1spt-BR!2sbr"
                width="100%"
                height="450"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                class="rounded-lg shadow-lg"
              ></iframe>
            </div>
          </div>
          <div class="w-full px-4 md:w-6/12">
            <div class="flex flex-wrap">
              <div class="w-full px-4 md:w-6/12">
                <div class="relative mt-4 flex flex-col">
                  <div class="flex-auto px-4 py-5">
                    <div
                      class="mb-5 inline-flex h-12 w-12 items-center justify-center rounded-full bg-white p-3 text-center shadow-lg"
                    >
                      <i class="fa-solid fa-wifi"></i>
                    </div>
                    <h6 class="Dreamwood mb-1 text-2xl font-semibold">Wi-fi Gratuito</h6>
                    <p class="mb-4">Disponibilzamos Wi-fi gratuitamente para todos os hóspedes.</p>
                  </div>
                </div>
                <div class="relative flex min-w-0 flex-col">
                  <div class="flex-auto px-4 py-5">
                    <div
                      class="mb-5 inline-flex h-12 w-12 items-center justify-center rounded-full bg-white p-3 text-center shadow-lg"
                    >
                      <i class="fa-solid fa-mug-hot"></i>
                    </div>
                    <h6 class="Dreamwood mb-1 text-2xl font-semibold">Café da Manhã</h6>
                    <p class="mb-4">Café da manhã de qualidade incluso no valor do quarto.</p>
                  </div>
                </div>
              </div>
              <div class="w-full px-4 md:w-6/12">
                <div class="relative mt-4 flex min-w-0 flex-col">
                  <div class="flex-auto px-4 py-5">
                    <div
                      class="mb-5 inline-flex h-12 w-12 items-center justify-center rounded-full bg-white p-3 text-center shadow-lg"
                    >
                      <i class="fa-solid fa-utensils"></i>
                    </div>
                    <h6 class="Dreamwood mb-1 text-2xl font-semibold">Almoço & Jantar</h6>
                    <p class="mb-4">Restaurante serve almoço e jantar apenas para hóspedes da pousada.</p>
                  </div>
                </div>
                <div class="relative flex min-w-0 flex-col">
                  <div class="flex-auto px-4 py-5">
                    <div
                      class="mb-5 inline-flex h-12 w-12 items-center justify-center rounded-full bg-white p-3 text-center shadow-lg"
                    >
                      <i class="fa-solid fa-umbrella-beach"></i>
                    </div>
                    <h6 class="Dreamwood mb-1 text-2xl font-semibold">Praia</h6>
                    <p class="mb-4">Nossa pousada fica de frente com a praia, onde você pode</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Fotos da Pousada ▼ -->
    <div id="fotos" class="flex flex-wrap items-center pt-32">
      <div class="ml-auto mr-auto mt-48 w-full px-12 md:w-4/12 md:px-4">
        <h3 class="Dreamwood mb-2 text-3xl font-semibold leading-normal">Conheça nossa Pousada</h3>
        <p class="text-blueGray-600 mt-4 mb-4 text-lg font-light leading-relaxed">
          A pousada Tucano do Marahú foi construída em um lugar de raríssima beleza, o qual a natureza exuberante é o
          destaque.
        </p>
        <p class="text-blueGray-600 mt-4 mb-4 text-lg font-light leading-relaxed">
          O projeto arquitetônico exibe rusticidade, onde os 04 chalés e 06 apartamentos confortáveis ficam dispostos de
          modo a ver o espaço verde do entorno, parquinho, churrasqueira, piscinas, salão de festa.
        </p>
        <button class="font-bold transition-all duration-150 ease-linear" @click="showMultiple()">
          Todas as Imagens
          <i class="fa-solid fa-images"></i>
        </button>
      </div>
      <div class="mr-auto ml-auto mt-32 w-full px-4 md:w-6/12">
        <div class="relative flex flex-wrap justify-center">
          <div class="my-4 w-full px-4 lg:w-6/12">
            <img
              src="@/assets/images/antigas/horta.jpg"
              class="mt-8 h-40 w-full transform rounded-md object-cover shadow-lg transition-all duration-150 ease-linear hover:scale-105 hover:cursor-pointer"
              @click="showHorta()"
            />
            <img
              src="@/assets/images/antigas/Chale.jpg"
              class="mt-8 h-40 w-full transform rounded-md object-cover shadow-lg transition-all duration-150 ease-linear hover:scale-105 hover:cursor-pointer"
              @click="showChale()"
            />
            <img
              src="@/assets/images/antigas/academia03.jpg"
              class="mt-8 h-40 w-full transform rounded-md object-cover shadow-lg transition-all duration-150 ease-linear hover:scale-105 hover:cursor-pointer"
              @click="showAcademia()"
            />
          </div>
          <div class="my-4 w-full px-4 lg:mt-16 lg:w-6/12">
            <img
              src="@/assets/images/antigas/Panorama02.jpg"
              class="mt-8 h-40 w-full transform rounded-md object-cover shadow-lg transition-all duration-150 ease-linear hover:scale-105 hover:cursor-pointer"
              @click="showPanorama()"
            />
            <img
              src="@/assets/images/antigas/Panorama03.jpg"
              class="mt-8 h-40 w-full transform rounded-md object-cover shadow-lg transition-all duration-150 ease-linear hover:scale-105 hover:cursor-pointer"
              @click="showPraia()"
            />
            <img
              src="@/assets/images/antigas/Apartamento.jpg"
              class="mt-8 h-40 w-full transform rounded-md object-cover shadow-lg transition-all duration-150 ease-linear hover:scale-105 hover:cursor-pointer"
              @click="showApartamento()"
            />
          </div>
        </div>
      </div>
    </div>
    <vue-easy-lightbox
      scroll-disabled
      esc-disabled
      move-disabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
    <!-- Fotos da Pousada ▲ -->
    <!-- Acomodações ▼ -->
    <div id="acomodacoes" class="my-24 flex flex-wrap justify-center text-center">
      <div class="w-full px-12 md:w-6/12 md:px-4">
        <h2 class="lgtext-4xl text-3xl font-semibold">
          Nossas acomodações, confortáveis e elegantes para você relaxar e se divertir em família.
        </h2>
        <p class="mt-4 mb-4 text-lg leading-relaxed">
          A pousada Tucano do Marahú visa a preservação do patrimônio cultural e ambiental. Venha conhecer a pousada e
          aproveitar o melhor da natureza.
        </p>
        <router-link to="/accommodations">
          <button
            class="rounded-md bg-gradient-to-br from-orange-300 to-amber-600 py-2 px-4 font-bold text-white shadow-md transition-all duration-150 ease-linear hover:brightness-110"
          >
            Veja todas as acomodações aqui
          </button>
        </router-link>
      </div>
    </div>
    <section class="py-24">
      <div class="container mx-auto">
        <div class="flex flex-wrap justify-center">
          <div class="lg:w-12/12 -mt-24 w-full px-4">
            <div v-for="accommodation in allAccommodations" :key="accommodation.id" class="">
              <div v-if="accommodation.id <= 3" class="float-left w-full px-4 lg:w-4/12">
                <div class="flex justify-between">
                  <p class="pb-4 text-left text-xl font-semibold">
                    {{ accommodation.name }}
                  </p>
                  <p class="pb-4 text-right text-xl font-semibold">
                    {{ accommodation.value }}
                  </p>
                </div>
                <router-link :to="{ path: '/accommodation/' + accommodation.id }">
                  <div
                    class="relative mb-6 flex w-full min-w-0 flex-col break-words rounded-lg bg-white shadow-lg transition-all duration-150 ease-linear hover:brightness-110"
                  >
                    <img
                      alt="..."
                      class="h-auto max-w-full rounded-lg border-none align-middle"
                      src="@/assets/images/quartoEx.jpg"
                    />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--<section class="pt-64">
      <div class="container mx-auto">
        <div class="flex flex-wrap justify-center">
          <div class="lg:w-12/12 -mt-24 w-full px-4">
            <div class="flex flex-wrap">
              <div class="w-full px-4 lg:w-4/12">
                <h5 class="pb-4 text-center text-xl font-semibold">
                  Acomodação 1
                </h5>
                <router-link to="/auth/login">
                  <div
                    class="relative mb-6 flex w-full min-w-0 flex-col break-words rounded-lg bg-white shadow-lg transition-all duration-150 ease-linear hover:-mt-4"
                  >
                    <img
                      alt="..."
                      class="h-auto max-w-full rounded-lg border-none align-middle"
                      src="@/assets/images/quartoEx.jpg"
                    />
                  </div>
                </router-link>
              </div>

              <div class="w-full px-4 lg:w-4/12">
                <h5 class="pb-4 text-center text-xl font-semibold">
                  Acomodação 2
                </h5>
                <router-link to="/profile">
                  <div
                    class="relative mb-6 flex w-full min-w-0 flex-col break-words rounded-lg bg-white shadow-lg transition-all duration-150 ease-linear hover:-mt-4"
                  >
                    <img
                      alt="..."
                      class="h-auto max-w-full rounded-lg border-none align-middle"
                      src="@/assets/images/quartoEx.jpg"
                    />
                  </div>
                </router-link>
              </div>

              <div class="w-full px-4 lg:w-4/12">
                <h5 class="pb-4 text-center text-xl font-semibold">
                  Acomodação 3
                </h5>
                <router-link to="/landing">
                  <div
                    class="relative mb-6 flex w-full min-w-0 flex-col break-words rounded-lg bg-white shadow-lg transition-all duration-150 ease-linear hover:-mt-4"
                  >
                    <img
                      alt="..."
                      class="h-auto max-w-full rounded-lg border-none align-middle"
                      src="@/assets/images/quartoEx.jpg"
                    />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->
    <!-- Acomodações ▲ -->
    <!-- Rodapé ▼ -->
    <HomeFooter />

    <!-- Rodapé ▲ -->
    <!-- Mais informações ▲ -->
    <!-- sessão das acomodações API -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import HomeHeader from '@/components/home/HomeHeader.vue';
import HomeFooter from '@/components/home/HomeFooter.vue';
import VueEasyLightbox from 'vue-easy-lightbox';
export default {
  name: 'HomePage',
  components: {
    HomeHeader,
    HomeFooter,
    VueEasyLightbox,
  },
  data() {
    return {
      allAccommodations: [],
      imgs: '',
      visible: false,
      index: 0,
    };
  },
  computed: {
    ...mapState({
      watcher: (state) => state.watcher,
      router: (state) => state.router,
    }),
    ...mapGetters({
      getAccommodations: 'accommodations/getAccommodations',
    }),
  },
  mounted() {
    this.getAllAccommodations();
    addEventListener('scroll', () => {
      this.setScrollY(window.scrollY);
    });
    addEventListener('resize', () => {
      this.setViewportX(window.innerWidth);
    });
  },
  methods: {
    ...mapActions({
      setScrollY: 'watcher/setScrollY',
      setViewportX: 'watcher/setViewportX',
    }),
    async getAllAccommodations() {
      const accommodations = await this.getAccommodations.then((response) => response.accommodations);
      this.allAccommodations = accommodations;
    },
    showHorta() {
      this.imgs = ['img/horta/horta01.jpg', 'img/horta/horta02.jpg', 'img/horta/horta03.jpg'];
      this.show();
    },
    showPraia() {
      this.imgs = ['img/praia/praia01.jpg', 'img/praia/praia02.jpg', 'img/praia/praia03.jpg'];
      this.show();
    },
    showChale() {
      this.imgs = [
        'img/chale/chale01.jpg',
        'img/chale/chale02.jpg',
        'img/chale/chale03.jpg',
        'img/chale/chale04.jpg',
        'img/chale/chale05.jpg',
      ];
      this.show();
    },
    showPanorama() {
      this.imgs = [
        'img/panorama/panorama01.jpg',
        'img/panorama/panorama02.jpg',
        'img/panorama/panorama03.jpg',
        'img/panorama/panorama04.jpg',
        'img/panorama/panorama05.jpg',
        'img/panorama/panorama06.jpg',
      ];
      this.show();
    },
    showApartamento() {
      this.imgs = [
        'img/apartamento/apartamento01.jpg',
        'img/apartamento/apartamento02.jpg',
        'img/apartamento/apartamento03.jpg',
        'img/apartamento/apartamento04.jpg',
      ];
      this.show();
    },
    showAcademia() {
      this.imgs = ['img/academia/academia03.jpg', 'img/academia/academia02.jpg', 'img/academia/academia01.jpg'];
      this.show();
    },
    showMultiple() {
      this.imgs = [
        'img/chale01.jpg',
        'img/horta01.jpg',
        'img/Panorama01.jpg',
        'img/panorama01.jpg',
        'img/praia01.jpg',
        'img/Panorama04.jpg',
      ];
      this.index = 1;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    scrollSobre() {
      this.$router.push('/');
      setTimeout(() => {
        window.scrollTo({
          top: document.querySelector('#sobre').offsetTop,
          behavior: 'smooth',
        });
      }, 500);
    },
  },
};
</script>
