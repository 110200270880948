<template>
  <router-view></router-view>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  computed: {
    ...mapState({
      watcher: (state) => state.watcher,
      router: (state) => state.router,
    }),
  },
  mounted() {
    // this.updateUrl();
    addEventListener('scroll', () => {
      this.setScrollY(window.scrollY);
    });
    addEventListener('resize', () => {
      this.setViewportX(window.innerWidth);
    });
  },
  methods: {
    ...mapActions({
      setScrollY: 'watcher/setScrollY',
      setViewportX: 'watcher/setViewportX',
      updateUrl: 'url/updateUrl',
    }),
  },
};
</script>
