/**
 * Vue Js
 * Tailwind Css
 */
import './styles/tailwind.css';
import { createApp } from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox';

/**
 * Aplicação (App)
 * Roteamento (Router)
 * Armazenamento (Store)
 */
import app from './App.vue';
import store from './scripts/store/store.js';
import router from './scripts/router/routes.js';

/**
 * Cria o Aplicativo (Create App)
 * Adiciona o Roteamento (Router)
 * Adiciona o Armazenamento (Store)
 */
createApp(app).use(router).use(store).use(VueEasyLightbox).mount('body');
