export default {
  namespaced: true,
  state: {
    aba: 0,
    menu: 'data',
  },
  mutations: {
    setAba(state, aba) {
      state.aba = aba;
    },
    setMenu(state, menu) {
      state.menu = menu;
    },
  },
  actions: {
    setAba({ commit }, aba) {
      commit('setAba', aba);
    },
    setMenu({ commit }, menu) {
      commit('setMenu', menu);
    },
  },
  getters: {
    getAba: (state) => state.aba,
    getMenu: (state) => state.menu,
  },
};
