export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || '',
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
  },
  actions: {
    async signUp(
      { commit, rootGetters },
      { cpf, name, email, phone, password }
    ) {
      return await fetch(rootGetters['url/getUrl'] + 'api/user/create', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          phone,
          cpf,
          password,
          email,
        }),
      })
        .then((response) => {
          switch (true) {
            case response.status >= 100 && response.status < 200:
              return false;
            case response.status >= 200 && response.status < 300:
              commit('setToken', '');
              localStorage.removeItem('token');
              return true;
            case response.status >= 400 && response.status < 500:
              return false;
            case response.status >= 500 && response.status < 600:
              return false;
            default:
              return false;
          }
        })
        .catch((error) =>
          alert(`Erro ao tentar se comunicar com o servidor.\n\n${error}`)
        );
    },
    async signIn({ commit, rootGetters }, { email, password }) {
      return await fetch(rootGetters['url/getUrl'] + 'api/login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      })
        .then((response) => {
          switch (true) {
            case response.status >= 100 && response.status < 200:
              return false;
            case response.status >= 200 && response.status < 300:
              response.json().then((json) => {
                commit('setToken', json.original.access_token);
                localStorage.setItem('token', json.original.access_token);
              });
              return true;
            case response.status >= 400 && response.status < 500:
              return false;
            case response.status >= 500 && response.status < 600:
              return false;
            default:
              return false;
          }
        })
        .catch((error) =>
          alert(`Erro ao tentar se comunicar com o servidor.\n\n${error}`)
        );
    },
    async signOut({ commit, state, rootGetters }) {
      return await fetch(rootGetters['url/getUrl'] + 'api/logout', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.token}`,
        },
      })
        .then((response) => {
          switch (true) {
            case response.status >= 100 && response.status < 200:
              return false;
            case response.status >= 200 && response.status < 300:
              commit('setToken', '');
              localStorage.removeItem('token');
              return true;
            case response.status >= 400 && response.status < 500:
              return false;
            case response.status >= 500 && response.status < 600:
              return false;
            default:
              return false;
          }
        })
        .catch((error) =>
          alert(`Erro ao tentar se comunicar com o servidor.\n\n${error}`)
        );
    },
  },
  getters: {
    async getUser(state, getters, rootState, rootGetters) {
      return await fetch(rootGetters['url/getUrl'] + 'api/me', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.token}`,
        },
      })
        .then((response) => {
          switch (true) {
            case response.status >= 100 && response.status < 200:
              return false;
            case response.status >= 200 && response.status < 300:
              return {
                user: response.json().then((json) => json),
                return: true,
              };
            case response.status >= 400 && response.status < 500:
              return false;
            case response.status >= 500 && response.status < 600:
              return false;
            default:
              return false;
          }
        })
        .catch((error) =>
          alert(`Erro ao tentar se comunicar com o servidor.\n\n${error}`)
        );
    },
    async getUsers(state, getters, rootState, rootGetters) {
      return await fetch(rootGetters['url/getUrl'] + 'api/users', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.token}`,
        },
      })
        .then((response) => {
          switch (true) {
            case response.status >= 100 && response.status < 200:
              return false;
            case response.status >= 200 && response.status < 300:
              return {
                users: response.json().then((json) => json),
                return: true,
              };
            case response.status >= 400 && response.status < 500:
              return false;
            case response.status >= 500 && response.status < 600:
              return false;
            default:
              return false;
          }
        })
        .catch((error) =>
          alert(`Erro ao tentar se comunicar com o servidor.\n\n${error}`)
        );
    },
    async getAuthentication(state, getters, rootState, rootGetters) {
      return await fetch(rootGetters['url/getUrl'] + 'api/me', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.token}`,
        },
      })
        .then((response) => {
          switch (true) {
            case response.status >= 100 && response.status < 200:
              return false;
            case response.status >= 200 && response.status < 300:
              return true;
            case response.status >= 400 && response.status < 500:
              return false;
            case response.status >= 500 && response.status < 600:
              return false;
            default:
              return false;
          }
        })
        .catch((error) =>
          alert(`Erro ao tentar se comunicar com o servidor.\n\n${error}`)
        );
    },
  },
};
