import { createStore } from 'vuex';

import user from '@/scripts/store/api/user';
import url from '@/scripts/store/api/url';
import accommodations from '@/scripts/store/api/accommodations';
import reservation from '@/scripts/store/api/reservation';

import router from '@/scripts/store/modules/router';
import watcher from '@/scripts/store/modules/watcher';
import menu from '@/scripts/store/modules/menu';

export default createStore({
  modules: {
    user,
    url,
    accommodations,
    reservation,
    router,
    watcher,
    menu,
  },
});
