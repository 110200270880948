export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async calculate(
      { rootGetters },
      { checkIn, checkOut, hotelRate, adults, children, pets }
    ) {
      console.log(
        JSON.stringify({
          checkIn,
          checkOut,
          hotelRate,
          adults,
          children,
          pets,
        })
      );
      return await fetch(
        rootGetters['url/getUrl'] + 'api/reservation/calculate',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            checkIn,
            checkOut,
            hotelRate,
            adults,
            children,
            pets,
          }),
        }
      )
        .then((response) => {
          switch (true) {
            case response.status >= 100 && response.status < 200:
              return false;
            case response.status >= 200 && response.status < 300:
              return response.json().then((json) => json);
            case response.status >= 400 && response.status < 500:
              return false;
            case response.status >= 500 && response.status < 600:
              return false;
            default:
              return false;
          }
        })
        .catch((error) =>
          alert(`Erro ao tentar se comunicar com o servidor.\n\n${error}`)
        );
    },
  },
  getters: {},
};
